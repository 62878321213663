

.register {
    padding: 10px 80px;

    :global(.register-title){
        font-size: 24px;
    }

    :global(.btn-register-home) {
        color: #fff;
        background-color: #1567B4;
        border-color: #1567B4;
        width: 256px;
        height: 56px;
        border-radius: 28.5px;
    }
}
