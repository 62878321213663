#header-home-silde{
    .btn-light{
        border: 1px solid #1567B4;
        background-color: transparent;
        color: #1567B4;
    }
    .btn-light-white{
        border: 1px solid #1567B4;
        background-color: white;
        color: #1567B4;
    }
}