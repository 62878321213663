#header-home-silde{
    .btn-light{
        border: 1px solid #1567B4;
        background-color: transparent;
        color: #1567B4;
    }
    .btn-light-white{
        border: 1px solid #1567B4;
        background-color: white;
        color: #1567B4;
    }
    .w-80 {
        width: 80%;
    }
    .w-60 {
        width: 60%;
    }
    .confirmBy {
        margin-left: 10%;
    }
    .font-head48 {
        font-size: 48px;
        font-weight: 600;
    }
    .font-head56 {
        font-size: 56px;
        font-weight: 600;
    }
    .font-head70 {
        font-size: 70px;
        font-weight: 600;
    }
    .font-detail30 {
        font-size: 30px;
        font-weight: 400;
    }
    .font-head-blue {
        color: #115CA3;
    }
}
